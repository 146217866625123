"use strict";

module.exports = (option) => {
  const DELAY_SHOW_MESSAGE = option.delayShowMessage;

  let messageShowDelay = option.timeShowFirstMessage;

  const chatNode = document.querySelector(option.rootNode);
  const mesageNodes = chatNode.querySelectorAll(option.messageNode);


  const getCoordsNode = (elem) => {
    let node = elem.getBoundingClientRect();
    return node.top + pageYOffset - document.documentElement.clientHeight
  };

  const CHAT_NODE_COORDINATES_Y = getCoordsNode(chatNode);

  const showMessageNode = () => {
    mesageNodes.forEach((element) => {
      messageShowDelay += DELAY_SHOW_MESSAGE;
      setTimeout(() => {
        element.classList.add(option.classShowMessage)
      }, messageShowDelay);
    });
  };

  window.addEventListener("scroll", () => {
    if(pageYOffset > CHAT_NODE_COORDINATES_Y) {
      showMessageNode();
    };
  });
};