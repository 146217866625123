module.exports = (option) => {
  const nodeWithPseudo = document.querySelectorAll(option.labelNode);
  

  nodeWithPseudo.forEach((element) => {
    const inputNode = element.querySelector(option.inputNode);

    inputNode.addEventListener(`focus`, ()=> {
      element.classList.add(option.hiddenClass)
    })
    
    inputNode.addEventListener(`blur`, ()=> {
      setTimeout(() => {
        if (inputNode.classList.contains('valid')) {
          element.classList.add(option.hiddenClass)
        } else {
          element.classList.remove(option.hiddenClass)
        }
      }, 50)
    })
  })
};