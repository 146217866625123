/*

    :: Развернуть список пошагово v. 1.3 ::

Сворачивает список до определенного значения

Принимает настройки:

*rootTag - класс корня списка (не обязательно ul)
*itemTag - класс пунктов списка (по умолчанию ищет теги li)
*numberDisplayItem - количество видимых пунктов
*readMoreTag - тег элемента с текстом для кнопки разворота
[displayItemIndicator] - показать кол-во оставшихся пунктов
[delay] - скорость появления пунктов (по умолчанию 50) 
[textForOpen]: "показать еще",
[textForClose]: "скрыть",
[noClose]: false

Действие:
при инициализации: 
- добавляет скрывающимся пунктам display: none, 
- при клике на кнопке разворота добавляет скрывающимся пунктам display: block,

Подключение: 
// раскрывающийся список
let expandList = require("./expandListStep");

Настройки:
// раскрывающийся список
expandListStep({
    rootTag: ".js-expand-list-step",
    itemTag: ".js-expand-item",
    numberDisplay: true,
    numberDisplayItem: "4",
    readMoreTag: ".js-read-more",
    displayItemIndicator: true,
    delay: 30,
    textForOpen: "показать еще",
    textForClose: "скрыть",
    animationClass: "is-appear",
    noClose: false
});


*/

module.exports = function (option) {

    // проверка
    if (option.rootTag && option.itemTag) {
        if (document.querySelector(option.rootTag) && document.querySelector(option.itemTag)) {
            run(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден аккордион");
            } else {
                if (!document.querySelector(option.itemTag)) {
                    console.error("не найден контейнер");
                }  
                if (!document.querySelector(option.readMoreTag)) {
                    console.error("не найден элемент, раскрывающий список");
                } else {
                    console.log(document.querySelector(option.readMoreTag)); 
                }                 
            } 
        }
    } else {
        console.error("не заданы параметры списка"); 
    }

    // запуск
    function run () {
        // console.log("Подключен пошаговый раскрывающийся список " + option.rootTag);
        let rootElList = document.querySelectorAll(option.rootTag);
        rootElList = Array.from(rootElList);
        let numberDisplayItem = option.numberDisplayItem;
        let delay = option.delay || 50;
        let showNumberHidden = option.showNumberHidden || true;
        let textForOpen = option.textForOpen || "показать еще ";
        let textForClose = option.textForClose || "свернуть ";
        let animationClass = option.animationClass || "";

        // основной код        
        let setInit = function () {
            
            // перебор списков
            rootElList.forEach (function (rootEl) {

                // проверка на наличие пунктов (могут быть списки без пунктов)
                if (rootEl.querySelector(option.itemTag) ) {
                    let itemList = rootEl.querySelectorAll(option.itemTag);
                    itemList = Array.from(itemList);                    
                    let readMoreEl = rootEl.querySelector(option.readMoreTag);                    
                    let isOpen = false;
                    let numberAllItems = itemList.length; 
                    let openEl,
                        closeEl; 


                    // закрытие
                    let close = function() {
                        // console.log("close"); 
                        // выбираем пункты для закрытия, создаем новый массив
                        var closeList = itemList.filter(function(item, i) {                                                        
                            return (i >= numberDisplayItem);                         
                        });
                        // разворачиваем массив
                        closeList.reverse();
                        let delayLocal = delay;
                        closeList.forEach (function (item) {
                            item.classList.add(animationClass);                           
                            setTimeout(function(){
                                item.style.display = "none";                                                                                            
                            },delayLocal);
                            delayLocal = delayLocal + delay; 
                        });  
                        openEl.style.display = "inline"; 
                        closeEl.style.display = "none"; 
                    };

                    let open = function() {
                        // console.log("open");
                        let delayLocal = delay; 
                        itemList.forEach(function(item, i) {  
                            // console.log(i); 
                            if (i >= numberDisplayItem) {
                                setTimeout(function(){
                                    item.style.display = "block";
                                        item.classList.add(animationClass);
                                },delayLocal);
                                delayLocal = delayLocal + delay;                               
                            } 
                        }); 
                        openEl.style.display = "none"; 
                        closeEl.style.display = "inline";                      
                    }; 

                    // инициализация
                    (function() {    

                        // проверка количества скрытых элементов
                        let numberHiddenItems = numberAllItems - numberDisplayItem;
                        let numberHiddenText = "";
                        if (showNumberHidden) {
                            numberHiddenText = numberHiddenItems;
                        }

                        // показать количество скрытых пунктов?
                        let toHideIndicator = "";
                        if (!option.displayItemIndicator) {
                            toHideIndicator = "display: none";
                        } 
                                                
                        // скрытие кнопки при инициализации списка
                        readMoreEl.style.display = "none";                        
                        readMoreEl.innerHTML = `<span class="expand-list-step__pseudolink">
                                                    <span class="expand-list-step__text-for-open">${textForOpen} 
                                                        <span class="expand-list__number-hidden" style="${toHideIndicator}">${numberHiddenText}</span>
                                                    </span>
                                                    <span class="expand-list-step__text-for-close">${textForClose}</span>
                                                </span>`;

                                              
                   
                        if (numberHiddenItems > 0) {                            

                            // показать кнопку раскрытия
                            readMoreEl.style.display = "inline-block";

                            // и добавить ей события
                            readMoreEl.onclick = function() {
                                if (isOpen) {  

                                    if (!option.noClose) {
                                        close();
                                        isOpen = false;                            
                                    }    
                                } else {  
                                    open();
                                    isOpen = true; 

                                    if (option.noClose) {
                                        readMoreEl.style.display = "none";
                                    }                                    
                                }
                            };                            
  
                        }

                        setTimeout(function(){
                            openEl = readMoreEl.querySelector(".expand-list-step__text-for-open"); 
                            closeEl = readMoreEl.querySelector(".expand-list-step__text-for-close"); 
                            openEl.style.cursor = "pointer"; 
                            closeEl.style.cursor = "pointer"; 

                            close();
                            isOpen = false;                                     
                        },50);

                    })();

                }
                
            });
        }


        setInit();  
    }

};


